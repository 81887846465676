.header {
    padding: 2rem 0;
    text-align: center;

    box-shadow: 0 6rem 4rem rgba(0,0,0,0.2);
    background: rgba(0,0,0,0.2);
    @include breakpoint(small only){ padding: 0; box-shadow: 0 1rem 2rem rgba(0,0,0,0.2); }

}

.menu-primary {
    letter-spacing: 2px;
    &__link{
        color: $light-text;
        font-family: $heading-font;
        font-size: 1rem;
        font-weight: 600;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        &:hover{
            color: $red;
        }
        @include breakpoint(small only){ display: block; font-size: 1.2rem; padding: 10px 0;  }
    }
    .menu {
        text-align: center;

    }
}

.wordmark {
    font-size: 2rem;
    font-family: $heading-font;
    font-weight: 300;
    letter-spacing: 1.5rem;
    padding: 2rem 0 1rem;
    text-transform: uppercase;
    @include breakpoint(small only){ padding: 1rem 0 0; }

    &__link {
        font-family: $heading-font;
        color: $gold;
        display: block;
        background: -webkit-linear-gradient(top, #ffffff 0%,#ffffff 50%,#dbcf99 51%,#dbcf99 100%);
        -webkit-text-fill-color: transparent;
        -webkit-background-clip: text;
        @include breakpoint(small only){ font-size: 1.5rem; letter-spacing: 1rem; }


    }
}
