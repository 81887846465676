/*
"To use the Float Grid in Foundation v6.4+, you need to:
In Sass: set both $xy-grid and $global-flex to false."
Reference: https://foundation.zurb.com/sites/docs/grid.html

We're using the new XY Grid, so I'm not overriding anything here.
*/
//$xy-grid: false;
//$global-flexbox: false;
//$global-flex: false;

/*
~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
* Overriding things in the _settings.scss file:
~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
*/
// * Make the page wider:
$global-width: 120rem;

// * Chage the widths of the larger breakpoints because I made the page wider:
$breakpoints: (
  small: 0,
  medium: 640px,
  large: 1024px,
  //xlarge: 1200px,
    xlarge: 1440px,
  //xxlarge: 1440px,
    xxlarge: 120rem, // ...This is as wide as the site goes.
);
// * Add xlarge and xxlarge classes so that we can include HTML classes for larger screens, thus: <div class="xxlarge-6 cell">:
$breakpoint-classes: (small medium large xlarge xxlarge);