.bio-container{
        @include breakpoint(small only){ min-height: 90vh; }
}
.intro {

    &.intro--bio {
        background: rgba(#000, 0.5);
        border-radius: $default-radius;
        padding: 2rem;
 }

    &__copy {
        p {
            line-height: 2 !important;
            text-align: left;
            @include breakpoint(small only){ line-height: 1.5 !important; }
        }
    }

}
.bio{
    &__two-column{
        @include breakpoint (xlarge down){ columns: 2; column-gap: 3rem; }
        @include breakpoint (medium down){ columns: 1; column-gap: 3rem; }
    }
}