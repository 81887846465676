.dr-thomas-yu-container.has-bg-image{
    //height: 50vh;
    @include breakpoint(small){ height: 50vh; min-height: 350px;}
}
.documents {
    @extend .music-entry;
    //max-width: 1000px;
    margin: 0 auto;
    &__figure {
        display: flex;
        justify-content: center;
        align-items: flex-end !important;
        box-shadow: 0px 3px 5px rgba(#000, 0.15);
        padding-bottom: 2rem;
        @include breakpoint (small only){ align-items: center !important; }
        &.effect-bubba{
            @include default-transition();
            @include breakpoint(small only){ margin: 1rem 0; padding: 2rem;}
            background: #fff;
            &:hover{
                //background: lighten($gold, 20%);
                :before,
                :after{
                    border: 0;
                    display: none;
                }
            }
            p{
                color: $red;
                font-family: $heading-font;
                font-weight: 600;
            }
            h2{
                color: darken($gold, 20%);
                font-size: 1.5rem;
                font-weight: 600;
            }
        }
    }
    &__figcaption{
        padding: 1rem !important;
    }
    &__link {
        //background: $gold;
        border-radius: 3px;
        display: block;
        width: 100%;
    }
    &__img{
        max-width: 35%;
        @include breakpoint(small only){ opacity: 0.2 !important; }
        &--Thomas-yu-authorization{
            max-width: 33%;
            @include breakpoint(medium only){ max-width: 30%; }
        }
        &--Thomas-yu-medical-history{
            max-width: 45%;
            @include breakpoint(medium only){ max-width: 42%; }
        }
    }
}
.dr-yu{
    &__google-map{
        @include breakpoint(xlarge){ margin-top: 4rem; }
        margin-bottom: 1rem;
    }
}
