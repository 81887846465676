/* That's it: */
.mouse {
  display: block;
  position: absolute;
  margin: 0 auto;
  width: 26px;
  height: 46px;
  border-radius: 13px;
  border: 2px solid $gold;
  bottom: 20px;
  left: 50%;
  @include default-transition;
  @include breakpoint(medium down){ display: none; }
  &:hover{
    border: 2px solid $red;
    @include default-transition;
    span{
        background: $red;
        @include default-transition;
    }
  }
}
.mouse span {
  display: block;
  margin: 6px auto;
  width: 3px;
  height: 3px;
  border-radius: 50%;
  background: $gold;
  border: 1px solid transparent;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-name: scroll;
  animation-name: scroll;
  @include default-transition;
}
@-webkit-keyframes scroll {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(16px);
    transform: translateY(16px);
  }
}
@keyframes scroll {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(16px);
    -ms-transform: translateY(16px);
    transform: translateY(16px);
  }
}
.tooltip{
    background: transparent;
    border: 1px solid $gold;
    border-radius: 100rem;
    color: $gold;
    font-family: $heading-font;
    font-size: 1rem;
    &.top{
        &::before{
            border-color: $gold transparent transparent;
            color: $gold;
        }
    }

}