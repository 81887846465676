.press-kit-container{
		height: 50vh;
        @include breakpoint(small){ height: 50vh; min-height: 350px;}
}
.press-kit-entry{
    &__title{
        color: #DBCF99;
        color: $red;
        font-family: $heading-font;
        font-size: 2rem;
        letter-spacing: -1px;
        margin: 0;
        @include breakpoint(medium down){ font-size: 2rem; letter-spacing: 0; }
    }
    .grid{
        margin: 1rem 0;
        @include breakpoint(medium down){ margin: 1rem 0; }
        figure{

            height: auto;
        }
    }
    &__img{

    }
    &__figcaption{
        display: flex;
        align-items: flex-end;
        justify-content: center;
        p{
            line-height: 1.2;
            padding: 0;
        }
    }
    &__caption{
        //margin-bottom: 3rem;
        p{
            margin: 0;
        }
    }
}
.press-kit-entry{
    border: 0;
    padding: 0;
}
.short-bio{
    text-align: left;
    margin: 0 0 2rem;
}