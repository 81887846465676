.media-container{
    @include breakpoint(small only){ min-height: 90vh; }
}
.listing--media{
    padding-bottom: 10rem;
}
.media-entry{
    border: 0;
    background: transparent;
    text-align: left;
    &__h2{
        color: darken($gold, 20%);
        font-family: $heading-font;
        font-size: 2rem;
        letter-spacing: -1px;
        line-height: 1.2;
        font-weight: 600;
        margin-top: 1rem;
        @include breakpoint(medium down) {
            font-size: 1.5rem;
            letter-spacing: 0;
            margin: 0;
        }
        @include breakpoint(small only){margin: 1rem 0; }
    }
    &__figure{
        height: auto !important;
        max-height: fit-content;
    }
    &__excerpt{

        &::before,
        &::after{
            content: open-quote;
            display: block;
            height: 0;
            line-height: 0;
            left: -1rem;
            position: relative;
            top: 10rem;
            color: rgba($gold, 0.2);
            font-size: 25rem;
            z-index: -10;
            font-family: $body-font;
            @include breakpoint(small only){ top: 6rem; font-size: 15rem; }
        }
        &::after{
            content: close-quote;
            top: -1.5rem;
            text-align: right;
            opacity: 0;
        }
    }
    &__h3{
        left: auto;
        line-height: 1.2;
        right: -1rem;
        margin-top: -0.5rem;
        @include breakpoint(medium down){ margin: 0 0 1rem; }
    }
    &__date{
        color: $light-text;
        color: darken($gold, 20%);
        font-family: $heading-font;
        font-size: 1.2rem;
        font-weight: 600;
        line-height: 1.2;
        //letter-spacing: -1px;
        @include breakpoint(medium down){ font-size: 1.2rem; letter-spacing: 0; line-height: 1.2; margin: 0; }
        @include breakpoint(small only){ display: block;  }
    }
    &__byline{
        color: $light-text;
        color: darken($gold, 20%);
        font-family: $heading-font;
        font-size: 1.2rem;
        font-weight: 600;
        line-height: 1.2;
        //letter-spacing: -1px;
        @include breakpoint(medium down){ font-size: 1.2rem; letter-spacing: 0; line-height: 1.2; margin: 0; }
        @include breakpoint(small only){ display: block;  }
    }
    &__publication{
        color: $light-text;
        color: darken($gold, 20%);
        font-family: $heading-font;
        font-size: 1.2rem;
        font-weight: 600;
        line-height: 1.2;
        //letter-spacing: -1px;
        @include breakpoint(medium down){ font-size: 1.2rem; letter-spacing: 0; line-height: 1.2; margin: 0; }
        @include breakpoint(small only){ display: block;  }
    }
}
.media-entry__figcaption {
    .media-entry__credit {
        opacity: 1;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 2rem;
    }
}
