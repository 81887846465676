// * More styles for this section in _global.scss.
.live-container{
    background-position-x: left !important;
    @include breakpoint(small only){ min-height: 90vh; }
}
.intro {
    @include breakpoint(medium down) {
        padding: 0;
    }
    &__h2 {
        color: #DBCF99;
        font-family: $heading-font;
        font-size: 5rem;
        font-weight: 900;
        letter-spacing: -1px;
        line-height: 1;
        @include breakpoint(large down) {
            font-size: 4rem;
        }
        @include breakpoint(medium down) {
            font-size: 3rem;
            letter-spacing: 0;
        }
        &--media{
            font-size: 4rem;
            letter-spacing: 0;
            @include breakpoint(large down) {
                font-size: 3rem;
            }
            @include breakpoint(medium down) {
                font-size: 2rem;
                font-weight: 600;
            }
        }
    }
    &__date{
        border-bottom: 1px solid #ccc;
        display: inline-block;
        font-family: $heading-font;
        line-height: 2;
        &--media{
            border: 0;
        }
    }
    &__details{
        margin-top: 5px;
    }
    &__venue {
        display: block;
        font-family: $heading-font;
        font-weight: 300;
    }
    &__publication {
        display: block;
        font-family: $heading-font;
        font-weight: 300;
        line-height: 2;
    }

    &__location {
        border-top: 1px solid #ccc;
        display: inline-block;
        font-family: $heading-font;
        font-weight: 300;
    }
}
.listing{
    &--shows{
        margin-bottom: 0rem;
    }
}
.listing-entry {
    //align-items: center;
    background: lighten($gold, 20%);
    //border-top: 1px solid $gold;
    //border-bottom: 1px solid $gold;
    //border-radius: 5px;
    //display: flex;
    //justify-content: center;
    margin: -1px auto 0;
    padding: 2rem 0 1rem;
    text-align: center;
    @include breakpoint(small only){ border: 0; }

    &--pending { // Upcoming shows
        //border-bottom: 1px solid $gold;
        border-radius: 5px;
        margin-bottom: 3rem;
        padding: 0;
        @include breakpoint(medium down) {
            margin-bottom: 1rem;
        }
        &__image {
            border-radius: 5px 0 0 5px;
            margin: 0;
            position: relative;
            &--left{
                position: relative;
                &:after{
                    content: "";
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 200px 64px 200px 0;
                    border-color: transparent lighten($gold, 20%) transparent transparent;
                    position: absolute;
                    right: -1px;
                    @include breakpoint(small only){ display: none; }
                }
            }
            &--right{
                position: relative;
                &:before{
                    content: "";
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 200px 0 200px 64px;
                    border-color: transparent transparent transparent lighten($gold, 20%) ;
                    position: absolute;
                    left: -1px;
                    @include breakpoint(small only){ display: none; }
                }
            }

        }
        &__copy {
            align-items: center;
            display: flex;
            height: 100%;
            justify-content: center;
            min-height: 400px;
            padding: 1rem;
            width: 100%;
            @include breakpoint(small only){ min-height: 0; }
        }
        &__h2 {
            color: darken($gold, 20%);
            font-family: $heading-font;
            font-size: 3rem;
            font-weight: 900;
            letter-spacing: 0px;
            @include breakpoint(medium down) {
                font-size: 1.5rem;
                letter-spacing: 0;
            }
        }
        &__location {
            border-top: 1px solid #ccc;
            display: inline-block;
            font-family: $heading-font;
            font-weight: 300;
            @include breakpoint(medium down) {
                font-size: 1rem;
            }
        }
        &__venue {
            display: block;
            font-family: $heading-font;
            font-weight: 300;
            @include breakpoint(medium down) {
                font-size: 1rem;
            }
        }
        &__details {
            margin: 2rem 0;
            >* {
                font-size: $body-font-size;
                @include breakpoint(medium down) {
                    font-size: 1rem;
                }
            }
        }
    }
    &--live { // Past shows.
        border-radius: $default-radius;
        margin: 1rem 0;
        padding: 0rem !important;
        @include breakpoint(medium down) {
            margin-bottom: 0;
        }
        &__image {}
        &__copy {
            border-radius: $default-radius;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            //margin-top: 1rem;
            padding: 1rem;
        }
        &__h2 {
            color: darken($gold, 20%);
            font-family: $heading-font;
            font-size: 2rem;
            letter-spacing: -1px;
            font-weight: 600;
            @include breakpoint(medium down) {
                font-size: 1.5rem;
                letter-spacing: 0;
            }
        }
        &__location {
            border-top: 1px solid #ccc;
            display: inline-block;
            font-family: $heading-font;
            font-weight: 300;
            @include breakpoint(medium down) {
                font-size: 1rem;
            }
        }
        &__venue {
            display: block;
            font-family: $heading-font;
            font-weight: 300;
            @include breakpoint(medium down) {
                font-size: 1rem;
            }
        }
        &__details {
            margin: 2rem 0;
            @include breakpoint(medium down) {
                margin: 1rem 0;
            }
            >* {
                font-size: $body-font-size;
                @include breakpoint(medium down) {
                    font-size: 1rem;
                }
            }
        }
        &__btn{
            padding: 1rem 2rem !important;
        }
    }
}
.has-bg-image--live{
    background: #00000075;
    color: $light-text;
    //margin-top: 1rem;
    padding: 2rem;
    width: 100%;
    // ! Stupid hack to undo something I'm doing above:
    &.listing-entry--live__copy{
        margin: 0;
    }
}
