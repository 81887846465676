nav{
  a:visited{
    color: #fff;
  }
}

nav.desktop-nav{
    display: block;
    margin-top: -1rem;
    @media only screen and (max-width: 640px) { display: none; }
}
.nav-toggle{ /* For mobile */
    display: none;
  }
  .mobile-nav{
      display: none;
      @media only screen and (max-width: 640px) {
        background: rgba(black, 0.9);
        box-shadow: 0 1rem 2rem rgba(0,0,0,0.9);
        display: none;
        height: 100vh;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;

        z-index: 100; }
      &__ul{
        margin-top: 8rem;
      }
  }
  /* Nav rules for mobile: */
  @media screen and (max-width: 640px) { /* When the screen width gets smaller than the container. */
      .nav-toggle{
          display: inline-block !important;
          //float: right;
          //height: 100%;
          //margin: 0 1rem 0 0;
          //text-align: right;
          //padding: 0.5rem;
          text-shadow: none;
          border-radius: 0 0 3px 3px !important;
          z-index: 100;
      }
      .nav-toggle:hover{
          cursor: hand;
          cursor: pointer;
      }
  }

// * START Hamburger icon code
#hamburger span,
#cross span {
      transition:.25s ease-in-out;
    display: block;
    background:#fff;
    border-radius:2px;
  }

  #menu-toggle {
    width:100px;
        width: 60px;
    height:60px;
    //margin:50px auto;
    position:relative;
    position:relative;
    cursor:pointer;
    //background:rgba(255,255,255,.4);
    border-radius:5px;
    &:hover {
      //background:rgba(255,255,255,.8);
    }
    #hamburger {
      position:absolute;
      height:100%;
      width:100%;
      span {
        width:40px;
        height:4px;
        position:relative;
        top:10px;
        left:10px;
        margin:7px 0;
        &:nth-child(1){
          transition-delay:.5s;
        }
        &:nth-child(2){
          transition-delay:.625s;
        }
        &:nth-child(3){
          transition-delay:.75s;
        }
      }
    }
    #cross {
      position:absolute;
      height:100%;
      width:100%;
      transform:rotate(45deg);
      span{
        &:nth-child(1){
          height:0%;
          width:4px;
          position:absolute;
          top:10%;
          left:28px;
          transition-delay:0s;
        }
        &:nth-child(2){
          width:0%;
          height:4px;
          position:absolute;
          left:10%;
          top:28px;
          transition-delay:.25s;
        }
      }
    }
  }

  #menu-toggle.open {
    #hamburger {
      span {
        width:0%;
        &:nth-child(1){
          transition-delay:0s;
        }
        &:nth-child(2){
          transition-delay:.125s;
        }
        &:nth-child(3){
          transition-delay:.25s;
        }
      }
    }
    #cross {
      span {
        &:nth-child(1){
          height:80%;
          transition-delay:.625s;
        }
      }
      span {
        &:nth-child(2){
          width:80%;
          transition-delay:.375s;
        }
      }
    }
  }
// * END Hamburger icon code

.pagination{
  margin: 2rem 0 0;
  font-family: $heading-font;
  font-weight: 600;
  width: 100%;
  a{
    background: $red !important;
    color: $light-text;
    padding: 0.5rem 1rem;
    &:hover{
      background-color: darken($red, 10%) !important;
      color: $light-text;
    }
    &.pagination__link{
      &--next{
        border-radius: 5px 100rem 100rem 5px;
      }
      &--previous{
        border-radius: 100rem 5px 5px 100rem;
      }
    }
  }
  .current{
    background: $gold !important;
    color: $dark-text;
    padding: 0.5rem 1rem;
  }
}