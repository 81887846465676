.music-container{
    @include breakpoint(xlarge){ min-height: 1100px; }
    @include breakpoint(small only){ min-height: 90vh; }
}

.music-entry{
    background: rgba($gold, 0.2);
    border: 1px solid rgba($gold, 0.3);
    border-radius: $default-radius;
    display: block;
    margin: 1rem auto;
    padding: 2rem;
    text-align: center;
    @include breakpoint(small only){ margin: 1rem 0; padding: 1rem; }
    &__h2{
        color: $light-text;
        color: darken($gold, 20%);
        font-family: $heading-font;
        font-size: 2rem;
        font-weight: 600;
        letter-spacing: -1px;
        margin: 2rem 2rem 0;
        @include breakpoint(xlarge down){ font-size: 1.5rem; letter-spacing: 0;  line-height: 1.2; margin: 0; padding: 0 0 0.2rem; }
    }
    &__composer{
        border-top: 1px solid #ccc;
        color: $dark-text;
        display: inline-block;
        line-height: 1.5;
        font-weight: 300;
        margin: 0rem 2rem 2rem;
        @include breakpoint(xlarge down){ font-size: 1rem; line-height: 1.2; margin: 0; padding: 0.2rem 0 0.5rem; }
    }
    &__venue{
        display: block;
        font-family: $heading-font;
        font-weight: 300;
    }
    &__details{
        margin: 2rem 0;
    }
}

.intro--music{
    //padding: 0;
    overflow: hidden;
    //@include breakpoint(small only){ display: none; }

}

// * Override Plyr.js styles:
.plyr__play-large{
    border-radius: 100rem;
}
.plyr--video,
.plyr--audio {
    border-radius: 10px;
    //box-shadow: 0 5px 20px rgba(#000, 0.1);
}
.plyr__progress--played, .plyr__volume--display{
    color: $red;
}
.range-thumb{
    background: $red;
}

// * Override intro stuff just for this page:
.intro{
    @include breakpoint(xlarge down) { margin: 0; padding: 1rem 0 5rem; }
    .plyr{
        margin: 0 auto !important;
        max-width: 80% !important;
    }
    &__h2{
        &--music{
            font-size: 4rem;
            letter-spacing: 0;
            @include breakpoint(large down) {
                font-size: 3rem;
            }
            @include breakpoint(medium down) {
                font-size: 2rem;
                font-weight: 600;
            }
        }
    }
    .music-entry{
        &__h2{
            color: $gold;
            font-weight: 600;
        }
        &__composer{
            border-top: 1px solid rgba($gold, 0.5);
            color: $light-text;
        }
    }
}


// Styles for the embedded YouTube videos (https://www.w3schools.in/load-youtube-video-dynamically/)
.vplayer {
    border-radius: $default-radius;
    margin-bottom: 30px;
    position: relative;
    padding-top: 56.25%;
    overflow: hidden;
    cursor: pointer;
    background-color: #000;
}
.vplayer img {
    top: -17%;
    left: 0;
    width: 100%;
}
.vplayer .plybtn {
    box-shadow: 0 0 30px rgba( 0, 0, 0, 0.6);
    width: 90px;
    height: 60px;
    background-color: #aa0505e6;
    z-index: 1;
    opacity: 0.8;
    border-radius: 6px;
}
.vplayer .plybtn:before {
    content: "";
    border-style: solid;
    border-width: 15px 0 15px 26.0px;
    border-color: transparent transparent transparent #fff;
}
.vplayer img,
.vplayer .plybtn {
    cursor: pointer;
}
.vplayer img,
.vplayer iframe,
.vplayer .plybtn,
.vplayer .plybtn:before {
    position: absolute;
}
.vplayer .plybtn,
.vplayer .plybtn:before {
    top: 50%;
    left: 50%;
    transform: translate3d( -50%, -50%, 0);
}
.vplayer iframe {
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}