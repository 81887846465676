label{
    font-family: $body-font;
    font-size: 1rem;
    text-align: left;
}
textarea{
    height: 200px;
}
form.contact-form{
    @extend .music-entry;
    margin-top: 0 !important;
}
.errors{
    &__item{
        //background: #fff;
        font-weight: bold;
        color: $red;
        //padding: 1rem;
    }
}
