// VARIABLES
/*
~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
Variables
~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
*/
$red: #DC143C;
    $red: #AA0505;
$gold: #DBCF99;
$gray: #555;

$heading-font: 'Raleway', sans-serif;
$body-font: 'Lora', serif;
$light-text: #f7f7f7;
$dark-text: #333333;
//$heading-color: $dark-blue;
//$link-on-dark-color: lighten($green, 15%);

$default-radius: 5px;
$body-font-size: 1.1rem;
$default-text-shadow: 0px 1px 5px rgba(#000, 0.5);