
// * Start background video styles. there's also a rule about this on the body tag above:
video#bgvid {
    position: fixed;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: 120vh;
    z-index: -100;
    transform: translateX(-50%) translateY(-50%);
  background: url('../images/bg-home.jpg') no-repeat;
  background-size: cover;
  transition: 1s opacity;
  @include breakpoint(800px down){ display: none; }
}
// * When the background video finishes playing, it pauses. This controls the opacity of the video at that point:
.stopfade {
   //opacity: .5;
}

body.home-page{
  // * Don't let the background video play on small devices:
  @include breakpoint (800px down){
    background: url(../images/bg-home.jpg) #000 no-repeat center center fixed;
    background-size: cover;
  }
}
 .video-dimmer{
   background: rgba(0, 0, 0, 0.5);
   height: 100vh;
   position: absolute;
   top: 0;
   bottom: 0;
   left: 0;
   right: 0;
   width: 100vw;
   z-index: -1;
   @include breakpoint (800px down){
     //background: url(../images/bg-home.jpg) #000 no-repeat center center fixed;
     background-size: cover;
     opacity: 0.4;
    }
   //@include breakpoint(small only){ display: none; }
 }
 // * End background video styles.


.home-show,
.home-blog{
  color: $light-text;
  padding: 0;
  margin-bottom: 5rem;
  text-align: center;
  width: auto;
  &__h2{
    font-size: 1rem;
    font-weight: 300;
    letter-spacing: 5px;
  }
  &__h3{
    color: $gold;
    font-size: 2rem;
    font-weight: 600;
    letter-spacing: 0px;
    @include breakpoint(small only){ font-size: 1.5rem; }
  }
 }

 .tagline {
  border: 0;
  font-family: $body-font;
  font-size: 4rem;
  //margin: -15% 15% 0;
  //    margin: 0 15% 0;
  color: $light-text;
  line-height: 1.3;
  text-align: center;
  text-shadow: 0rem 0.5rem 3rem rgba(#000, 0.5);
  @include breakpoint(xlarge down){ font-size: 3rem; }
  @include breakpoint(large down){ font-size: 2rem; }
  @include breakpoint(small only){ font-size: 1.5rem; margin: 0 0 4rem;}
  @include default-transition;


  &__emphasis {
      color: $gold;
      font-size: 6rem;
      font-family: $heading-font;
      font-weight: 900;
      vertical-align: sub;
      @include breakpoint(xlarge down){ font-size: 5rem; }
      @include breakpoint(large down){ font-size: 3rem; }
      @include breakpoint(small only){ font-size: 2rem; }
  }

  &__cite {
      color: $light-text;
      font-size: 1.5rem;
      margin-top: 2rem;
      text-align: right;
      @include breakpoint(xlarge down){ font-size: 1rem; }
      @include breakpoint(small only){ text-align: center; }
  }

  &:before {
      content: open-quote;
      display: block;
      height: 0;
      line-height: 0;
      left: 0;
      position: relative;
      top: 8rem;
      color: rgba($light-text, 0.2);
      font-size: 6em;
      text-align: left;
      text-shadow: none;
      //z-index: -10;
      @include default-transition;
  }

  &::after {
      content: close-quote;
      display: block;
      height: 0;
      line-height: 0;
      right: 0;
      position: relative;
      top: -1rem;
      color: rgba($light-text, 0.2);
      font-size: 6em;
      text-align: right;
      text-shadow: none;
      //z-index: -10;
      @include default-transition;
  }
}
//This is a change
