/*
~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
Styles
~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
*/
* {
    -webkit-font-smoothing: subpixel-antialiased;
}

.wow { visibility: hidden; }

h1, h2, h3, h4, h5, h6{
    font-family: $heading-font;
}

.page-title{
    bottom: 25vh;
        bottom: 2rem;
    color: $gold;
    font-family: $heading-font;
    font-size: 10rem;
    font-weight: 100;
    left: 3rem;
    letter-spacing: -0.5rem;
    line-height: 1;
    position: absolute;
    // Blog entry titles are followed by the date:
    &__date{
        display: block;
        font-family: inherit;
        letter-spacing: 0;
        margin-left: 0.7rem;
        @include breakpoint(xlarge down) { margin-left: 0.2rem;}
        @include breakpoint(small only) { margin-left: 0rem;}
    }
    @include breakpoint(xlarge down) { font-size: 4rem; font-weight: 200; letter-spacing: 0; left: 2rem; }
    @include breakpoint(small only) { font-size: 2rem; font-weight: 300; letter-spacing: 0; left: 1rem; }
}

.has-bg-image{
    //border-bottom: 7px solid $gold;
    //border-radius: 0 0 10rem 0;
    box-shadow: inset 0rem -10rem 200rem  rgba(#000, 1);
  display: block;
  position: relative;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0;
  width: 100%;
      height: 90vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  @media only screen and (max-height: 1000px) { min-height: 1000px; }
  @include breakpoint(medium down) { background-size: 100vh; background-attachment: scroll; }
  @include breakpoint(small only){ min-height: 90vh; height: auto; background-attachment: scroll; }
  }
  .listing-entry-page .has-bg-image{
  //height: 50vh;
  //background-attachment: inherit;
  }
.intro{
    color: #f7f7f7;
    font-family: "Lora",serif;
    font-size: 1.5rem;
    margin-top: 10%;
    text-align: center;
    padding: 5rem 1rem;
    /*
    position: absolute;
    width: 40%;
    bottom: 20%;
    right: 10%;
    */
    //border: 1px solid rgba($gold, 0.3);
    border-radius: 5px;
    padding: 2rem;
    //box-shadow: 0px 10px 75px rgba(#000, .5);
    //border: 1px solid rgba($gold, 0.3);
    border-radius: $default-radius;
    //background: rgba(#000, .5);
    @include default-transition;
    @include breakpoint(large down) { margin: 0; padding: 5rem 0; }
    @include breakpoint(small only) { padding: 0; }

    p{
        line-height: 1.6;
        @include breakpoint(small only) { line-height: 1.5; }
    }
}



.main-content{
    padding: 3rem 0 0;
    @include breakpoint (small only) { padding: 2rem 0 0; }
    &--bio{
        background: transparent url('../site-design-images/smoke-look.jpg') no-repeat bottom right;
        background-size: 60vw;
        //@include breakpoint (xlarge down){ columns: 2;-webkit-columns: 2; -moz-columns: 2;}
        //@include breakpoint (medium down){ columns: 1;-webkit-columns: 1; -moz-columns: 1; background-size: 100vw}
        @include breakpoint (small only){ background-size: 160vw}
    }

    &--bio{
        padding-bottom: 5rem;
        @include breakpoint(small only){ padding-bottom: 15rem; }
    }
}
p, span{
    font-family: $body-font;
    font-size: $body-font-size;
    font-weight: normal;
    line-height: 2;
    @include breakpoint(small only) { line-height: 1.5; }
}
span{
    font-size: 1.3rem;
}
a, a:visited{
    color: $red;
    @include default-transition;
    &:hover{
    color: darken($red, 10%);
    @include default-transition;
    }
}
a.btn,
.button{
    background-color: rgba($red, 0.9);
    border-radius: 100rem;
    color: $light-text;
    display: inline-block;
    font-family: $heading-font;
    font-size: 1.1rem;
    font-weight: 400;
    letter-spacing: 1px;
    margin: 1rem 0;
    padding: 1rem 3rem;
    box-shadow: 0 5px 20px rgba(#000, 0.3);
    @include breakpoint(small only) { font-weight: normal; }
    &:hover{
        background-color: darken($red, 10%);
        color: $light-text;
    }
    &--on-dark{
        background-color: rgba($red, .5);
        &:hover{
            background-color: darken(rgba($red, .5), 10%);
            color: $light-text;
        }
    }
    &:visited{
        color: $light-text;
    }
}
.pullquote-container{
    background: transparent url('../images/white-bg-pattern.png') repeat;
    background: #AA0505;
    background: transparent url('../site-design-images/gradient-edit.jpg') no-repeat 0 0;
    background-size: cover;
    color: white;
    margin: 2rem 0;
    padding: 10rem 0;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
    position: relative;
    @include breakpoint(medium down){ padding: 5rem 0rem; }
}
.quotation-mark-container{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    overflow: hidden;
}
.quotation-mark{
    color: rgba($gold, 0.1);
    font-size: 50rem;
    font-family: $body-font;
    text-shadow: none;
    margin-top: 20%;
    display: block;
    @include breakpoint(xlarge down){ margin-top: 20%; }
    @include breakpoint(large down){ font-size: 50rem; margin-top: 30%; }
    @include breakpoint(medium down){ font-size: 30rem; margin-top: 30%; }
    @include breakpoint(small only){ font-size: 30rem; margin-top: 50%; }
}
.pullquote{
    border: 0;
    text-align: center;
    margin: 0;
    &__p{
        color: $light-text;
        font-size: 2.5rem;
        font-style: italic;
        line-height: 1.5;
        margin-bottom: 0.5rem;
        @include breakpoint (large down){ font-size: 2rem; }
        @include breakpoint (small only){ font-size: 1.5rem; }
    }
    &__cite{
        color: $light-text;
        font-family: $heading-font;
        font-style: normal;
        font-size: 1.5rem;
        @include breakpoint (medium down){ font-size: 1rem; font-weight: 300;}
    }
}
.mailing-list{
    background: transparent url('/dist/site-design-images/mailing-list-bg.jpg') no-repeat center center;
    background-size: cover;
    height: 830px;
    display: flex;
    align-items: center;
    text-align: center;
    @include breakpoint(xlarge down){ height: 560px; }
    @include breakpoint(large down){ height: 430px;}
    @include breakpoint(small only){ background: transparent url('/dist/site-design-images/mailing-list-bg.jpg') no-repeat right center; background-size: cover; display: flex; height: auto; padding: 5rem 1rem; }
    &__copy{
        align-self: auto;
        @include breakpoint(medium down){ padding: 1rem; align-self: flex-end; }
    }
    &__input{
        font-family: $body-font;
    }
}
// * Rounded fields for mailing list (https://foundation.zurb.com/building-blocks/blocks/input-group-rounded.html):
.input-group-rounded {
    .input-group-field {
      border-radius: 5000px 0 0 5000px;
    font-family: $body-font;
    padding: 1.5rem;
    }

    .input-group-button .button {
      border-radius:  0 5000px 5000px 0;
      box-shadow: none;
    }
  }

.full-height{
    height: 100vh;
}


.has-smoky-bg{
    align-items: center;
    background: transparent url('../site-design-images/smoke-jacket.jpg') no-repeat bottom right;
    background-size: contain;
    display: flex;
    min-height: 800px;
    position: relative;
    @include breakpoint (medium down){ background-size: 100vw; align-items: flex-start; text-align: center; }
    &--music{
        background: transparent url('../site-design-images/smoke-jacket.jpg') no-repeat bottom right;
        background-size: contain;
        @include breakpoint (small only){ background-size: 175%; min-height: 700px;}
    }
    &--live{
        background: transparent url('../site-design-images/smoke-focus.jpg') no-repeat bottom right;
        background-size: contain;
        @include breakpoint (medium down){ background-size: cover;}
        @include breakpoint (small only){ background-size: 175%; min-height: 670px;}

    }
    &--presskit{
        background: transparent url('../site-design-images/smoke-introductions.jpg') no-repeat bottom right;
        background-size: contain;
        @include breakpoint (medium down){ background-size: 125%; }
        @include breakpoint (small only){ background-size: 175%; min-height: 700px;}
    }
    &--media{
        background: transparent url('../site-design-images/smoke-point.jpg') no-repeat bottom right;
        background-size: contain;
        @include breakpoint (medium down){ background-size: 125%; }
        @include breakpoint (small only){ background-size: 175%; min-height: 700px;}
    }
    &--blog{
        background: transparent url('../site-design-images/smoke-ohyeah.jpg') no-repeat bottom right;
        background-size: contain;
        @include breakpoint (medium down){ background-size: 125%; }
        @include breakpoint (small only){ background-size: 175%; min-height: 700px;}
    }
}

.social-list {
    //margin: 0.5rem 0 0;
    justify-content: left;
    @include breakpoint(medium down){ justify-content: center; }
    &__item{
        max-width: 85px;
    }

}
.social__img{
    width: 100%;
}
.credits{
    //background: $light-text;
    font-size: 0.9rem;
    position: absolute;
    bottom: 1rem;
    @include breakpoint (medium down){ color: #fff; left: 1rem; right: 1rem; }
    &__link{
        font-family: $heading-font;
        font-weight: 600;
        @include breakpoint (small only){ color: #fff !important; }
    }
}

h2.normal-heading {
    color: darken($gold, 20%);
    display: table;
    white-space: nowrap;
    font-size: 2.5rem;
    font-weight: 300;
    margin-bottom: 3rem;
    @include breakpoint(small only){ font-size: 1.5rem; margin-bottom: 1rem; }
        &:before, &:after {
            border-top: 1px solid $gold;
            content: '';
            display: table-cell;
            position: relative;
            top: 1.8rem;
            width: 43%;
        }
        &:before { right: 2.5%; }
        &:after { left: 2.5%; }
    @include breakpoint(small only){
        &:before, &:after {
            top: 1rem;
            width: 48%;
        }
    }
    // In the Press Kit, the heading "Press Photos" is shorter than normal, so we need to widen the lenths of the :before and :after elements only for that case:
    &--presskit{
        margin-top: 3rem;
        &:before, &:after {
            width: 44%;
        }
    }
}
