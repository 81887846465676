.captioned-image{
    margin: 5rem 0;
    @include breakpoint(small only) { margin: 2rem 0; }
}
figure{
	margin:0 0 1rem;
	//padding: 0.5rem;
	overflow: hidden;
	figcaption,
	figcaption p{
		//@include body-style;
		//color: $branding-color-three;
		padding: 0.2rem 0;
		font-size: 0.8rem;
		font-style: italic;
        text-align: center;
        line-height: 1.2;
	}
	img{
        border-radius: 15px;
		padding: 0.5rem;
		//background: $branding-color-five;
		height: auto !important;
		width: 100%;
		//width: 100%;
        @include default-transition;
	}
	&.no-style{
		img{
			background: transparent;
		}
    }
    &.captioned-image{
        &--left{
            float: left;
            margin: 0 2rem 2rem 0;
            margin-left: -24%;
            max-width: 75%;
            @include breakpoint(large down) { margin-left: 0; max-width: 50%; }
            @include breakpoint(medium down) { max-width: 100%; width: 100%; clear: both;}
        }
        &--right{
            float: right;
            margin: 0 0 2rem 2rem;
            margin-right: -24%;
            max-width: 75%;
            @include breakpoint(large down) { margin-right: 0; max-width: 50%; }
            @include breakpoint(medium down) { max-width: 100%; width: 100%; clear: both;}
        }
        &--center{
            width: auto;
            margin-right: 0;
            margin-left: 0;
            @include breakpoint(large down) { margin-left: 0; margin-right: 0; }
            @include breakpoint(medium down) { max-width: 100%; width: 100%; clear: both; }
        }
        &--oversized{
            width: auto;
            margin-right: -24%;
            margin-left: -24%;
            @include breakpoint(large down) { margin-left: 0; margin-right: 0; }
            @include breakpoint(medium down) { max-width: 100%; width: 100%; clear: both;  }
        }
    }
}