.blog-page.single-entry{
	.has-bg-image{
		height: 75vh;
		@include breakpoint (medium down){
		height: 75vh;
		}
		@include breakpoint (small only){
		box-shadow: inset 0rem -4rem 6rem #000;
		min-height: 50vh;
		}
	}
}
.listing--blog{
	margin-top: 5rem;
	@include breakpoint(medium down){ margin: 0;}
	.blog-entry{
		background: rgba($gold, 0.2);
		//border: 1px solid rgba($gold, 0.3);
		box-shadow: 0rem 1rem 10rem rgba(black, 0.2);
		border-radius: 5px;
		display: block;
		margin: 1rem auto;
		padding: 0rem;
		text-align: center;
		&__title{
//			background: rgba(#000, 0.3);
			border-radius: $default-radius;
			color: #DBCF99;
			color: $light-text;
			font-family: $heading-font;
			font-size: 2rem;
			font-weight: 600;
			letter-spacing: -1px;
			line-height: 1;
			margin: 0;
			padding: 0 0 0.7rem;
			text-shadow: $default-text-shadow;
			@include breakpoint(medium down){ font-size: 2rem; letter-spacing: 0; }
			@include breakpoint(small only){ font-size: 1.2rem; line-height: 1; margin-bottom: -0.5rem;}
		}
		&__link{
			color: $light-text;
		}
		&__composer{
			border-top: 1px solid #ccc;
			color: $gray;
			display: inline-block;
			font-family: $heading-font;
			font-weight: 300;
		}
		&__venue{
			display: block;
			font-family: $heading-font;
			font-weight: 300;
		}
		&__details{
			margin: 2rem 0;
		}
		&__date{
			border-top: 1px solid rgba($gold, 0.5);
			display: inline-block;
			font-family: $heading-font !important;
		}
	}
}

/*---------------*/
/***** Bubba *****/
/*---------------*/
.grid {
	position: relative;
    clear: both;
        padding: 1rem;
	margin: 0 auto;
	//padding: 1em 0 4em;
	max-width: 100%;
    list-style: none;
    overflow: hidden;
	text-align: center;
	@include breakpoint(small only){ padding: 0; }
}

/* Common style */
.grid figure {
        border-radius: 5px;
        display: flex;
        align-items: center;
	position: relative;
	//float: left;
	overflow: hidden;
	//margin: 10px 1%;
	// min-width: 320px;
	// max-width: 480px;
	// max-height: 360px;
    // width: 48%;
        margin: 0;
	height: 300px;
	background: #3085a3;
	text-align: center;
	cursor: pointer;
	@include breakpoint(small only){ height: 200px; }
}

.grid figure img {
    border-radius: 0;
	position: relative;
    display: block;
    padding: 0;
	// min-height: 100%;
	// max-width: 100%;
	opacity: 0.8;
}

.grid figure figcaption {
	padding: 4em;
    color: #fff;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	@include breakpoint(small only){ padding: 2rem; }
    p{
		font-size: 1.1rem;
		font-style: normal;
		font-family: $body-font;
		font-weight: 300;
		@include breakpoint(small only){ font-size: 1rem; line-height: 1.2; }
	}
}

.grid figure figcaption::before,
.grid figure figcaption::after {
	pointer-events: none;
}

.grid figure figcaption,
.grid figure figcaption > a {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

/* Anchor will cover the whole item by default */
/* For some effects it will show as a button */
.grid figure figcaption > a {
	z-index: 1000;
	white-space: nowrap;
	opacity: 0;
}
.grid figure h2{
    //padding: 2rem 0 0;
}
.grid figure p{

}
/*---------------*/
/***** Bubba *****/
/*---------------*/

figure.effect-bubba {
    background: rgba(0,0,0,1);
    background: -moz-linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0.1) 200%);
    background: -webkit-gradient(left bottom, right top, color-stop(0%, rgba(0,0,0,1)), color-stop(100%, rgba(0,0,0,0.1)));
    background: -webkit-linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0.1) 200%);
    background: -o-linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0.1) 200%);
    background: -ms-linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0.1) 200%);
    background: linear-gradient(0deg, rgba(#000,1) 0%, rgba($red,1.5) 150%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#000000', GradientType=1 );
}

figure.effect-bubba img {
	opacity: 1;
	-webkit-transition: opacity 0.35s;
    transition: opacity 0.35s;

	-webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
	transition: opacity 0.35s, transform 0.35s;
	-webkit-transform: scale(1.12);
    transform: scale(1.1);
}

figure.effect-bubba:hover img {
    opacity: 0.5;
	-webkit-transform: scale(1);
    transform: scale(1);
}
figure.effect-bubba figcaption::before,
figure.effect-bubba figcaption::after {
	position: absolute;
	top: 30px;
	right: 30px;
	bottom: 30px;
	left: 30px;
	content: '';
	opacity: 0;
	-webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
	transition: opacity 0.35s, transform 0.35s;
	@include breakpoint(small only){
		top: 10px;
		right: 10px;
		bottom: 10px;
		left: 10px;}
}

figure.effect-bubba figcaption::before {
    border-radius: 5px;
	border-top: 1px solid rgba($gold, 0.5);
	border-bottom: 1px solid rgba($gold, 0.5);
	-webkit-transform: scale(0,1);
	transform: scale(0,1);
}

figure.effect-bubba figcaption::after {
    border-radius: 5px;
	border-right: 1px solid rgba($gold, 0.5);
	border-left: 1px solid rgba($gold, 0.5);
	-webkit-transform: scale(1,0);
	transform: scale(1,0);



}

figure.effect-bubba h2 {
        color: $light-text;
	//padding-top: 30%;
	-webkit-transition: -webkit-transform 0.35s;
	transition: transform 0.35s;
	-webkit-transform: translate3d(0,60px,0);
	transform: translate3d(0,60px,0);
	//text-shadow: 0 0 5rem rgba(#000, 0.9);
}

figure.effect-bubba p {
	//padding: 20px 2.5em;
	opacity: 0;
	-webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
	transition: opacity 0.35s, transform 0.35s;
	-webkit-transform: translate3d(0,30px,0);
	transform: translate3d(0,30px,0);
}

figure.effect-bubba:hover figcaption::before,
figure.effect-bubba:hover figcaption::after {
	opacity: 1;
	-webkit-transform: scale(1);
	transform: scale(1);
}

figure.effect-bubba:hover h2,
figure.effect-bubba:hover p {
	opacity: 1;
	-webkit-transform: translate3d(0,0,0);
	transform: translate3d(0,0,0);
}

// Below we are making all the fancy blog image hover effects visible on smaller screens, even without hovering. This is so that you can read information about those entries when hover is impossible (like on touch screen smartphones). It's simpler to do inside one big media query as below:
@media only screen and (max-width: 1024px) {
	.blog-entry figure.effect-bubba img {
		opacity: 0.5;
		-webkit-transform: scale(1);
		transform: scale(1);
	}

	.blog-entry figure.effect-bubba figcaption::before,
	.blog-entry figure.effect-bubba figcaption::after {
		opacity: 1;
		-webkit-transform: scale(1);
		transform: scale(1);
	}

	.blog-entry figure.effect-bubba h2,
	.blog-entry figure.effect-bubba p {
		opacity: 1;
		-webkit-transform: translate3d(0,0,0);
		transform: translate3d(0,0,0);
	}
  }
