.footer{
    display: none;
    text-align: center;
    &__img{
        width: 100%;
    }
    &__p{
        color: $light-text;
        font-size: 0.9rem;
        font-family: $heading-font;
        font-weight: 300;
        text-shadow: 0 1px 20px rgba($dark-text, 0.8);
        a{
            font-family: $heading-font;
        }
    }
    &__link{
        color: $red;
        font-family: $heading-font;
    }
    &__credits{
        //margin-top: -10rem;
        border-radius: 5px;
        //box-shadow: 0 5px 20px rgba($dark-text, 0.7);
        //background: rgba($dark-text, 0.7);
        display: block;
        padding: 1rem 3rem;
        }
}
